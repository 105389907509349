export default {
  PUSH_COMPONENT: "PUSH_COMPONENT",
  OPEN_WALLET: "OPEN_WALLET",
  CLOSE_WALLET: "CLOSE_WALLET",
  OPEN_NETWORKS: "OPEN_NETWORKS",
  CLOSE_NETWORKS: "CLOSE_NETWORKS",
  OPEN_ERROR_SNACK: "OPEN_ERROR_SNACK",
  CLOSE_ERROR_SNACK: "CLOSE_ERROR_SNACK",
  OPEN_GAME_PLAY: "OPEN_GAME_PLAY",
  CLOSE_GAME_PLAY: "CLOSE_GAME_PLAY",
};

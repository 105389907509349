<template>
  <div>
    <v-sheet
      v-if="load"
      :loading="loading"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>
    <div v-else class="game-grid__item">
      <v-btn @click="changeLike(item.like)" class="like-btn" :class="{ 'isActive': item.like }"></v-btn>
      <div @click="openPlayGameModal" class="game-grid__item--inner">
        <v-img :src="item.game_icon_2 ? item.game_icon_2 : item.game_icon_1" class="thumbnail"></v-img>
        <div class="item-info">
          <div class="project-image">
            <v-img :src="item.game_icon_1"></v-img>
          </div>
          <div class="item-info__content">
            <div class="item-info__content--title">
              <p class="game-name">{{ item.game_name }}</p>
              <div class="game-type"><span>{{ item.is_pc ? 'PC' : '' }}</span><span>{{ item.is_mobile ? ', Mobile' : '' }}</span></div>
            </div>
            <div class="view-item-wrap">
              <div class="view-item">
                <div class="view-item__like"></div>
                <p>{{ nFormatter(item.like_count) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setLikeAPI, setUnlikeAPI } from '@/api/server/game/index.js';
export default {
  name: 'GameGridPlayItem',
  data() {
    return {
      url: '',
      msg_login: 'Need to connect wallet.',
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    load: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    playerId() {
      return this.$store.state.user.userInfo.playerId;
    },
  },
  mounted() {
  },
  methods: {
    openPlayGameModal() {
      const data = this.item
      this.$store.dispatch('openPlayGameModal', { data });
    },
    async reload() {
      await this.$emit('reload');
    },
    changeLike(state) {
      const data = 'Need to connect wallet.'
      if (!this.walletAddress) return this.$store.dispatch('openErrorSnack', { data });

      const { item } = this;
      const game_id = item.game_id;
      const player_id = this.playerId;
      if (!state) {
        setLikeAPI(game_id, player_id)
        .then(() => { this.reload() })
      } else {
        setUnlikeAPI(game_id, player_id)
        .then(() => { this.reload() })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
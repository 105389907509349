<template>
  <v-app id="app">
    <div class="wrapper">
      <app-bar />
      <div class="wrapper__inner">
        <router-view />
      </div>
      <app-footer />
    </div>
    <components v-for="item in components" :key="item.id" :is="item.component" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import AppBar from "@/components/common/AppBar"
import AppFooter from '@/components/common/AppFooter';
export default {
  name: 'App',
  data() {
    return {
      layout: 'none-layout',
    }
  },
  components: {
    AppBar,
    AppFooter,
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    ...mapState({
      components: state => state.components.items,
    }),
  },
  watch: {
    walletAddress() {
      if (this.walletAddress) {
        // this.$store.dispatch('getAllowance', this.walletAddress);
        this.$store.dispatch('getBalanceOf', this.walletAddress);
      }
    }
  },
  mounted() {
    this.$store.dispatch('checkUserInfo');
    this.$store.dispatch('checkChain');
  },
};
</script>

import types from './types';
import { getloggedInData } from '@/utils/cookie';
import { manager } from '@/libs/web3Manager';
import web3Utils from 'web3-utils';
import define from '@/define';
import config from '@/config';

const {
  SET_USER_INFO,
  REMOVE_USER_INFO,
  CHECK_USER_INFO,
  LOGIN,
  LOGOUT,
} = types;

const tokenSymbol = 'MEV'

const userModule ={
  state: {
    userInfo: {
      walletAddress: '',
      playerId: null,
    },
    userBalance: '',
    allowance: ''
  },
  getters: {
      userInfo: state => state.userInfo,
      userBalance: state => state.userBalance,
      allowance: state => state.allowance,
  },
  mutations: {
    [SET_USER_INFO](state, userInfo) {
      state.userInfo = userInfo;
      const now = new Date()
      const item = {
        value: userInfo,
        expiry: now.getTime() + 60*60*1000,
      }
      localStorage.setItem('userInfo', JSON.stringify(item));
    },
    [REMOVE_USER_INFO](state) {
      state.userInfo =  {
        walletAddress: '',
        playerId: null,
      },
      state.userBalance = '';
      state.allowance = '';
      localStorage.removeItem('userInfo');
    },
    userBalance(state, balance) {
      state.userBalance = balance;
    },
    allowance(state, allowance) {
      state.allowance = allowance;
    },
  },
  actions: {
    [LOGIN]({ commit }, { userInfo }) {
      commit(SET_USER_INFO, userInfo);
    },
    [LOGOUT]({ commit }) {
      commit(REMOVE_USER_INFO)
    },
    [CHECK_USER_INFO]({ commit }) {
      const userInfo = getloggedInData('userInfo');
      if (userInfo) {
        commit(SET_USER_INFO, userInfo);
      }
    },
    async getAllowance({ commit }, address) {
      if (!address) return;
      const router = config.contract[define.MEVERSE_NUMBER].game.address;
      await manager.getAllowance(tokenSymbol, address, router)
      .then(res => {
        const allowance = res;
        // console.log('allowance: ', res);
        commit('allowance', allowance);
      })
    },
    async getBalanceOf({ commit }, address) {
      if (!address) return;
      await manager.getBalanceOf(tokenSymbol, address)
      .then(res => {
        const balance = web3Utils.fromWei(res, 'ether');
        commit('userBalance', balance);
        // console.log('balance call: ', balance);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
  },
}

export default userModule;
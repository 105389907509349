import tokenABI from '@/assets/abi/tokenABI.json';
import gameABI from '@/assets/abi/gameABI.json';
import define from '@/define';

const MEVERSE = 0;

export default {
  [MEVERSE]: {
    token: {
      MEV: define.TOKEN.MEV,
      abi: tokenABI,
    },
    game: {
      address: define.GAME_ADDRESS,
      abi: gameABI,
    },
  },
}
import types from "@/store/components/types";

const {
  PUSH_COMPONENT,
  OPEN_WALLET,
  CLOSE_WALLET,
  OPEN_NETWORKS,
  CLOSE_NETWORKS,
  OPEN_GAME_PLAY,
  CLOSE_GAME_PLAY,
  OPEN_ERROR_SNACK,
  CLOSE_ERROR_SNACK,
} = types;

const components = {
  state: {
    items: [],
    wallet: {
      init: false,
      state: undefined,
    },
    networks: {
      init: false,
      state: undefined,
    },
    playGame: {
      init: false,
      state: undefined,
      data: {},
    },
    errorSnack: {
      init: false,
      state: undefined,
      data: '',
    },
  },
  getters: {
    getWalletInit: (state) => state.wallet.init,
    getNetworksInit: (state) => state.networks.init,
    getPlayGameInit: (state) => state.playGame.init,
    getErrorSnackInit: (state) => state.errorSnack.init,
  },
  actions: {
    openErrorSnack(context, { data }) {
      const isInit = context.getters.getErrorSnackInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "ErrorSnack" */ "@/components/items/SnackBar.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "ErrorSnack" });
      }
      context.commit(OPEN_ERROR_SNACK, { data });
    },
    closeErrorSnack(context) {
      context.commit(CLOSE_ERROR_SNACK);
    },
    openPlayGameModal(context, { data }) {
      const isInit = context.getters.getPlayGameInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "PlayGame" */ "@/components/modal/PlayGame.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "PlayGame" });
      }
      context.commit(OPEN_GAME_PLAY, { data });
    },
    closePlayGameModal(context) {
      context.commit(CLOSE_GAME_PLAY);
    },
    openWalletModal(context) {
      const isInit = context.getters.getWalletInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "Wallet" */ "@/components/modal/Wallet.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "Wallet" });
      }
      context.commit(OPEN_WALLET);
    },
    closeWalletModal(context) {
      context.commit(CLOSE_WALLET);
    },
    openNetworksModal(context) {
      const isInit = context.getters.getNetworksInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "Networks" */ "@/components/modal/Networks.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "Networks" });
      }
      context.commit(OPEN_NETWORKS);
    },
    closeNetworksModal(context) {
      context.commit(CLOSE_NETWORKS);
    },
  },
  mutations: {
    [PUSH_COMPONENT](state, { component, id }) {
      state.items.push({ component, id });
    },
    [OPEN_WALLET](state) {
      state.wallet = {
        init: true,
        state: true,
      };
    },
    [CLOSE_WALLET](state) {
      state.wallet.state = false;
    },
    [OPEN_NETWORKS](state) {
      state.networks = {
        init: true,
        state: true,
      };
    },
    [CLOSE_NETWORKS](state) {
      state.networks.state = false;
    },
    [OPEN_ERROR_SNACK](state, { data }) {
      state.errorSnack = {
        init: true,
        state: true,
        data
      };
    },
    [CLOSE_ERROR_SNACK](state) {
      state.errorSnack.state = false;
      state.errorSnack.data = {};
    },
    [OPEN_GAME_PLAY](state, { data }) {
      state.playGame = {
        init: true,
        state: true,
        data
      };
    },
    [CLOSE_GAME_PLAY](state) {
      state.playGame.state = false;
      state.playGame.data = {};
    },
  },
};
export default components;

<template>
  <div class="casc-wrap profile">
    <div class="casc-wrap__header profile">
      <div class="profile__header">
        <v-img v-if="walletAddress" class="profile__header--icon" :src="makeBlockie(walletAddress)"></v-img>
        <div v-else class="profile__header--icon bg"></div>
        <div v-if="walletAddress" class="profile__header--info">
          <p v-if="info.nic_name !== null">{{ info.nic_name }}</p>
          <p v-else>{{ `MPlayer_${playerId}` }}</p>
          <span>{{ concatAddress(walletAddress, 6, 5) }}</span>
        </div>
        <div v-else class="profile__header--info">
          <span>Please Connect your Wallet.</span>
        </div>
      </div>
    </div>

    <div class="casc-wrap__body view section">
      <div class="profile__body">
        <div class="tabs">
          <ul class="tabs__items">
            <li @click="onChangeTab('profile')" :class="{ 'isActive': tabState === 'profile' }">My Profile</li>
          </ul>
        </div>
        <div v-if="info.player_id">
          <component v-if="tabState === 'profile'" :is="MyProfileView" :player="info" @reload="init"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfoAPI } from '@/api/server/player/index.js';
import makeBlockie from 'ethereum-blockies-base64';
export default {
  name: 'ProfileView',
  data() {
    return {
      MyProfileView: () =>
        import(
          /* webpackChunkName: "MyProfileView" */ "./MyProfile.vue"
        ),
      tabState: 'profile',
      info: {}
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    playerId() {
      return this.$store.state.user.userInfo.playerId;
    },
  },
  watch: {
    walletAddress() {
      this.init();
    },
  },
  mounted() {
    this.init();
    if (this.$route.query.t) {
      this.tabState = this.$route.query.t;
    }
  },
  methods: {
    makeBlockie,
    onChangeTab(item) {
      if (this.tabState == item) return;
      this.tabState = item;
      this.$router.push(`/profile?t=${this.tabState}`);
    },
    refresh() {
      this.info = {}
    },
    init() {
      if (!this.walletAddress) return;
      this.refresh()
      getUserInfoAPI(this.walletAddress)
      .then(res => {
        this.info = res?.data;
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
  }
}
</script>

<style>
</style>
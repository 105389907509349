import Vue from 'vue'
import Meta from 'vue-meta';
import VueRouter from 'vue-router'
import { getloggedInData } from '@/utils/cookie';
import { connectToMetamask } from '@/utils/metamaskLogin';
import Home from "@/views/Home.vue";
import Profile from "@/views/profile/ProfileView.vue";
import Hyper from "@/views/gameZ/Hyper.vue";
import Social from "@/views/gameZ/Social.vue";
import store from "@/store/index.js"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requireLogin: true }
  },
  {
    path: '/hyper-gamez',
    name: 'hyper',
    component: Hyper
  },
  {
    path: '/social-gamez',
    name: 'social',
    component: Social
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next)=>{
  const loggedIn = getloggedInData('userInfo');
  const address = loggedIn?.walletAddress;

  if (to.fullPath != from.fullPath && address) {
    store.dispatch('getBalanceOf', address);
  }

  if(to.matched.some(record=>record.meta.requireLogin)) {
    if(!loggedIn){
      next('/')
      store.dispatch('logout')
      connectToMetamask()
    } else {
      next()
     }
  } else {
    next()
  }
})

Vue.use(Meta);

export default router

const env = process.env.VUE_APP_ENV;
const isProduction = env === 'production' ? true : false;

export default {
    URL: isProduction ? 'https://api.meversegame.io' : 'https://testapi.meversegame.io',
    DEX_API_URL: isProduction ? 'https://api.meversedex.io' : 'https://api-test.meversedex.io',
    DECIMAL18: 1e18,
    METAMASK: 'metamask',
    MEVERSE_DAY_PER_BLOCK: 172800,
    MEVERSE_NUMBER: 0,
    CHAIN_NAME: {
        MEVERSE: 'meverse',
    },
    TOKEN: {
        MEV: isProduction ? '0xef3432f1d54ec559613f44879fef8a7866da3e07' : '0xa1f093a1d8d4ed5a7cc8fe29586266c5609a23e8',
    },
    SYMBOL: {
        MEV: require(`../assets/media/Symbol/MEV.svg`),
    },
    GAME_ADDRESS: isProduction ? '0xf39d91121edb0d3b54ce4e5edf32eec5bfec4057' : '0xf39d91121edb0d3b54ce4e5edf32eec5bfec4057',
    COMMUNITY: {
        DISCORD: {
            clientId: '1072432245433974864',
            clientSecret: '4dDzAkIN-AvYgr4oi-TR0mV2bwn8Wf__',
            authorizeUrl: 'https://discord.com/api/oauth2/',
            redirectUri: 'https://www.meversegamex.io/profile?t=profile',
            urlScope: 'identify',
        }
    }
}
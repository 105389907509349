<template>
  <div class="connect-wallet-mobile">
    <v-btn v-if="!isAllowChain" @click="openNetworksModal" class="WrongNetwork"></v-btn>
    <div v-else-if="walletAddress" class="UserIn" @click="openWalletModal">
      <v-img :src="makeBlockie(walletAddress)"></v-img>
    </div>
    <v-btn v-else class="Connect" @click="login"></v-btn>
  </div>
</template>

<script>
import config from '@/config';
import makeBlockie from 'ethereum-blockies-base64';
import { connectToMetamask } from '@/utils/metamaskLogin';
import { concatAddress } from '@/utils/concat';
export default {
  name: "ConnectWalletMobile",
  data() {
    return {
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    chainId() {
      return this.$store.state.chain.chain.id;
    },
    isAllowChain() {
      if (window.ethereum && window.ethereum?.chainId) {
        const chainId = window.ethereum.chainId;
        if (config.allowChain.includes(chainId)) {
          return true;
        }
      }
      return this.$store.getters.isAllowChain;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    makeBlockie,
    concatAddress,
    async login() {
      await connectToMetamask();
    },
    openWalletModal() {
      this.$store.dispatch('openWalletModal');
    },
    openNetworksModal() {
      this.$store.dispatch('openNetworksModal');
    },
  }
}
</script>

<style>

</style>
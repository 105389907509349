import store from '@/store';
import { addLoginAPI } from '@/api/server/auth/index.js';
import { getUserInfoAPI } from '@/api/server/player/index.js';

const accountHandler = (accounts) => {
  if (accounts.length > 0) {
    store.dispatch('logout');
  } else {
    loginCheck(accounts[0]);
  }
}

const chainHandler = (id) => {
  store.dispatch('changeChainId', { chainId: id, type: 'metamask' });
}

const getChain = () => {
  window.ethereum.request({ method: 'eth_chainId' })
    .then(res => {
      store.dispatch('changeChainId', { chainId: res, type: 'metamask' });
    }
  );
}

export const connectToMetamask = () => {
  if (window.ethereum) {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.ethereum.request({ method: 'eth_requestAccounts' }).then(() => {
        const address = window?.ethereum?.selectedAddress;
        loginCheck(address);
      })
      .catch(e => {
        console.log('mobile error', e);
      })
    } else {
      window.ethereum
        .request({ method: 'wallet_requestPermissions',
        params: [{
          eth_accounts: {}
        }]
      }).then(() => {
        const address = window?.ethereum?.selectedAddress;
        loginCheck(address);
      })
      .catch(e => {
        console.log('error: ', e)
      })
    }
  } else {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = 'https://metamask.app.link/dapp/meversegamex.io';
      return;
    }

    window.open('https://metamask.io/download', 'metamask');
  }
};

// export const connectToMetamaskPromise = () => {
//   return new Promise((resolve, reject) => {
//     if (window.ethereum) {

//       window.ethereum
//         .request({ method: 'wallet_requestPermissions',
//         params: [{
//           eth_accounts: {}
//         }]
//       }).then(() => {
//         const address = window?.ethereum?.selectedAddress;
//         resolve(true);
//         loginCheck(address);
//       })
//       .catch(e => {
//         reject(e);
//       })
//     } else {
//       window.open('https://metamask.io/download', 'metamask');
//       reject();
//     }
//   })
// };

if (window.ethereum) {
  window.ethereum.on('accountsChanged', accountHandler);
  window.ethereum.on('chainChanged', chainHandler);
}
let prevAddress;
let interval;
// const sign = (message, address) => {
//   return new Promise((resolve, reject) => {
//     const params = [message, address];
//     const method = 'personal_sign';
//     try {
//       window.ethereum.sendAsync({ method, params }, (err, result) => {
//         resolve(result);
//       });
//     } catch(e) {
//       reject(e);
//     }
//   })
// }
const login = async (address) => {
  await store.dispatch('getBalanceOf', address);
  const balance = store.state.user.userBalance || 0;
  addLoginAPI(address, balance)
  .then(() => {
    getUserInfoAPI(address)
    .then(res => {
      const item = res?.data;
      store.dispatch('login', { userInfo: {
        walletAddress: address,
        playerId: item.player_id,
      }});
    })
    .catch(e => {
      console.log('getUserInfo e: ', e);
    })
  })
  .catch(e => {
    console.log('addLogin e: ', e);
  })
}

const loginCheck = (address) => {
  const prev = store.getters.userInfo.walletAddress;

  if (address === prev) {
    return;
  }

  if (prevAddress === address) {
    return;
  }

  if (interval) {
    clearTimeout(interval);
    interval = null;
    prevAddress = '';
    return;
  }

  interval = setTimeout(() => {
    login(address);
    prevAddress = '';
    interval = null;
  }, 50);

  prevAddress = address;
  getChain();
}
<template>
  <div class="connect-bar">
    <div v-if="!isAllowChain" class="wrong-network">
      <v-btn @click="openNetworksModal">Wrong Network</v-btn>
    </div>
    <div v-else-if="walletAddress" class="userIn">
      <div class="userIn__inner balance-w">
        <p>{{ Number(userBalance) ? toNumberFormat(userBalance) : '0.00' }} MEV</p>
      </div>
      <div @click="openWalletModal" class="userIn__inner wallet">
        <p>{{ concatAddress(walletAddress, 4, 4) }}</p>
        <v-img :src="makeBlockie(walletAddress)"></v-img>
      </div>
    </div>
    <div v-else class="connect">
      <v-btn @click="login">Connect Wallet</v-btn>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import makeBlockie from 'ethereum-blockies-base64';
import { connectToMetamask } from '@/utils/metamaskLogin';
export default {
  name: "ConnectWallet",
  data() {
    return {
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    userBalance() {
      return this.$store.state.user.userBalance;
    },
    chainId() {
      return this.$store.state.chain.chain.id;
    },
    isAllowChain() {
      if (window.ethereum && window.ethereum?.chainId) {
        const chainId = window.ethereum.chainId;
        if (config.allowChain.includes(chainId)) {
          return true;
        }
      }
      return this.$store.getters.isAllowChain;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    makeBlockie,
    async login() {
      await connectToMetamask();
    },
    openWalletModal() {
      this.$store.dispatch('openWalletModal');
    },
    openNetworksModal() {
      this.$store.dispatch('openNetworksModal');
    },
  }
}
</script>

<style>

</style>